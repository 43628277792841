:root {
    --bgcolor: black;
    --color: rgb(250,250,240);
    --trim:rgb(10,10,15);
    --trimlow:rgb(5,5,100);
    --trimlow2:rgb(20,20,25);
    --trimlow3:rgb(20,20,25);
    --none:rgba(0,0,0,0);

}

html, body{
  overflow-x:hidden;
  background-color: var(--bgcolor);
  color:var(--color);
  font-size: 18px;
}
.app {
  display: flex;
  flex-direction: row; /* Adjust to row for side-by-side columns */
  height: 100vh;
  padding: 0px;
  box-sizing: border-box;
  align-items: stretch; /* Ensures columns fill the container vertically */
}


.column {
  display: flex; /* Allows content inside columns to use flexbox properties */
  align-items: center; /* Optional: Remove if vertical centering is not desired in all columns */

}

.column.left, .column.right {
  width: 400px; /* Fixed width for left and right columns */

}

.column.center {
  flex-grow: 1; /* Takes remaining space */
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items:flex-end;

  border-left:1px solid var(--trim);
  border-right:1px solid var(--trim);

}

/* Responsive adjustments */
@media (max-width: 1300px) {
  .column.left, .column.right {
    width: 150px; /* Narrower columns when under 1000px */
  }
}

@media (max-width: 800px) {
  .column.left, .column.right {
    display: none; /* Hide columns when under 700px */
  }
}
