.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width:100%;
}

.textarea {
  max-width: 350px;
  width:100%;

  height: 250px;
  resize: none;
  outline: none;
  background-color: var(--bg);
  color:var(--color);
  font-size:12px;
  border:1px solid var(--trimlow2);
  color:rgb(100,100,100);
}

.space {
  height: 50px;
}


.textarea::-webkit-scrollbar {
    width: 12px;  /* You can adjust the width as needed */
}


.textarea::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
}

/* Styles the handle (thumb) of the scrollbar */
.textarea::-webkit-scrollbar-thumb {
    background-color: var(--trimlow3);
    border-radius: 6px;  /* Optional: Adds rounded corners to the thumb */
    border: 3px solid rgba(0,0,0,0);  /* Optional: Adds a border around the thumb */
}
.textarea::-webkit-scrollbar-track {
    background: var(--scrollbar-bg-color);  /* Set track background to blue */
}
