:root {
    --scrollbar-track-color: #f1f1f1;
    --scrollbar-thumb-color: rgba(1,1,5,1);
    --scrollbar-bg-color: rgba(10,10,255,0);
    --scrollbar-height: 400px;
    --placeholdercolor: rgba(50,50,50,1);
}


/*.messages {

  flex-direction: column;
  justify-content: flex-end;
  width: 600px;
  height: 800px;
  overflow-y: scroll;
  border: 0px solid #ccc;
  padding: 10px;

  border:1px solid blue;

}*/


.messages::-webkit-scrollbar {
    width: 12px;  /* You can adjust the width as needed */
}


.messages::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
}

/* Styles the handle (thumb) of the scrollbar */
.messages::-webkit-scrollbar-thumb {
    background-color: var(--trimlow3);
    border-radius: 6px;  /* Optional: Adds rounded corners to the thumb */
    border: 3px solid rgba(0,0,0,0);  /* Optional: Adds a border around the thumb */
}
.messages::-webkit-scrollbar-track {
    background: var(--scrollbar-bg-color);  /* Set track background to blue */
}


.messages {
    display: grid;
    grid-template-rows: 1fr;
    width: 100%;
    max-width: 700px;
    height: calc(100vh - 150px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;

}

.response:first-child {
    margin-top: auto;

}

.response {
    width: 90%;

}

.response {
  /*background-color: #f8f9fa;*/

  border-right: 3px solid var(--none);
  padding: 40px 20px 40px 0;
  margin: 10px 0;

}
.chat {
  display: flex;
  flex-direction: column;
  align-items: stretch;

}
.input-area {
  display: flex;
  justify-content: space-between;
  width: 600px;
  margin:50px 0;
  border: 1px solid var(--trim);
  padding:10px 30px;
  border-radius: 5px;
  background-color: var(--trimlow2);
}

input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;
   border: none;
   outline: none;
   background-color: var(--trimlow2);
   color:var(--color);
   font-size: 16px;
}

button {
  padding: 10px 20px;
  cursor: pointer;
}
